import { defineComponent } from "vue";
import { uploadImage } from "@/api/UploadImageApi";
import useNotyf from "@/composable/useNotyf";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { useI18n } from "vue-i18n/index";
import { translate } from "@/core/helpers/custom";
export default defineComponent({
    name: "ImageUploader",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var notification = useNotyf();
        // const removeImage = () => {
        //   emit("update:modelValue", "/no-image.png");
        // };
        var t = useI18n().t;
        var changeAvatar = function (e) {
            var formData = new FormData();
            formData.append("image", e.currentTarget.files[0]);
            var res = uploadImage(formData);
            res.then(function (_a) {
                var data = _a.data;
                emit("update:modelValue", data.data.profile.auth_user.image_path);
                notification.success(translate("Success"));
                store.dispatch(Actions.VERIFY_AUTH);
                res.catch(function () {
                    notification.success(translate("Error"));
                });
            });
        };
        return {
            changeAvatar: changeAvatar,
        };
    },
});
