import { __assign, __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "account-settings",
    components: {
        ErrorMessage: ErrorMessage,
        Field: Field,
        Form: Form,
    },
    setup: function () {
        var _this = this;
        var loading = ref({
            profileDetail: false,
            email: false,
            password: false,
            phone: false,
        });
        var store = useStore();
        var router = useRouter();
        var emailFormDisplay = ref(false);
        var passwordFormDisplay = ref(false);
        var phoneFormDisplay = ref(false);
        //Create form validation object
        var registerValidate = Yup.object().shape({
            firstname: Yup.string().required().label("First Name"),
            lastname: Yup.string().required().label("Last Name"),
        });
        var changeEmail = Yup.object().shape({
            email: Yup.string().required().email().label("Email"),
        });
        var changePhone = Yup.object().shape({
            phone: Yup.string().required().label("Email"),
        });
        var changePassword = Yup.object().shape({
            password: Yup.string().min(6).required().label("Password"),
            password_confirmation: Yup.string()
                .min(6)
                .required()
                .oneOf([Yup.ref("password"), null], "Passwords must match")
                .label("Password Confirmation"),
        });
        var profileDetails = ref({});
        var onSubmitUserProfile = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.profileDetail = true;
                        return [4 /*yield*/, store
                                .dispatch(Actions.UPDATE_USER, profileDetails.value)
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.profileDetail = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var updateEmail = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.email = true;
                        return [4 /*yield*/, store
                                .dispatch(Actions.UPDATE_USER, profileDetails.value)
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.email = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var updatePassword = function (_value, _a) {
            var resetForm = _a.resetForm;
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            loading.value.password = true;
                            return [4 /*yield*/, store
                                    .dispatch(Actions.UPDATE_USER_PASSWORD, profileDetails.value)
                                    .then(function () {
                                    resetForm();
                                })
                                    .catch(function () {
                                    "_";
                                })];
                        case 1:
                            _b.sent();
                            loading.value.password = false;
                            return [2 /*return*/];
                    }
                });
            });
        };
        var updatePhone = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.phone = true;
                        return [4 /*yield*/, store
                                .dispatch(Actions.UPDATE_USER_PHONE, {
                                number: profileDetails.value.phone,
                            })
                                .then(function () {
                                phoneFormDisplay.value = false;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        loading.value.phone = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var getData = function () { return __awaiter(_this, void 0, void 0, function () {
            var res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        loading.value.profileDetail = true;
                        return [4 /*yield*/, store.dispatch(Actions.VERIFY_AUTH)];
                    case 1:
                        res = _a.sent();
                        profileDetails.value = __assign(__assign({}, res.data.auth_user), { phone: res.data.phone });
                        loading.value.profileDetail = false;
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            setCurrentPageBreadcrumbs("Settings", ["Account"]);
            getData();
        });
        return {
            onSubmitUserProfile: onSubmitUserProfile,
            profileDetails: profileDetails,
            emailFormDisplay: emailFormDisplay,
            passwordFormDisplay: passwordFormDisplay,
            changeEmail: changeEmail,
            changePassword: changePassword,
            updateEmail: updateEmail,
            updatePassword: updatePassword,
            registerValidate: registerValidate,
            phoneFormDisplay: phoneFormDisplay,
            changePhone: changePhone,
            updatePhone: updatePhone,
            loading: loading,
            router: router,
        };
    },
});
